import App from './App.vue'
import router from './app/router'
import * as Vue from 'vue';
import PrimeVue from 'primevue/config';
import { store } from './app/stores'

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Inputnumber from "primevue/inputnumber";
import RadioButton from "primevue/radiobutton";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import Toolbar from "primevue/toolbar";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import Checkbox from "primevue/checkbox";
import Breadcrumb from "primevue/breadcrumb";
import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";
import Calendar from 'primevue/calendar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';



import { Line } from 'vue-chartjs'
import VueApexCharts from 'vue3-apexcharts'

const app = Vue.createApp(App)
app.use(router)
app.use(PrimeVue)
app.use(VueApexCharts)

app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Inputnumber', Inputnumber);
app.component('RadioButton', RadioButton);
app.component('Dropdown', Dropdown);
app.component('Textarea', Textarea);
app.component('Column', Column);
app.component('InputNumber', InputNumber);
app.component('Button', Button);
app.component('FileUpload', FileUpload);
app.component('Toolbar', Toolbar);
app.component('InputText', InputText);
app.component('Badge', Badge);
app.component('Checkbox', Checkbox);
app.component("Breadcrumb", Breadcrumb)
app.component("Card", Card)
app.component("Line", Line)
app.component("SelectButton", SelectButton)
app.component("Calendar", Calendar)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)
app.component("Image", Image)
app.component("Accordion", Accordion)
app.component("AccordionTab", AccordionTab)


app.use(store)

app.mount('#app');
