/* eslint-disable */
import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home'
import ErrorComponent from '@/components/Error';
import { Auth } from 'aws-amplify';

import UserComponent from '@/components/users/User';
import ClientComponent from '@/components/clients/Client';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            // beforeEnter: requireAuth
        },
        {
            path: '/logout', component: Home, beforeEnter(to, from, next) {
                Auth.signOut().then(() =>next("/"))
            }
        },
        {
            path: '/error', component: ErrorComponent
        },
        {
            path: '/clients', component: ClientComponent
        },
        {
            path: '/clients/:id', component: ClientComponent
        },
        {
            path: '/users', component: UserComponent
        },
    ]
})