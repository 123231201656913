<template>
  <div>
    <DataTable :value="chunks" v-model:expandedRows="expandedRows" dataKey="ts" @rowExpand="onRowExpand"
      @rowCollapse="onRowCollapse" :paginator="true" :rows="50" :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[50, 100, 500]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      responsiveLayout="scroll">
      <template #header>
        <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
          <Calendar v-model="dates" selectionMode="range" class="mt-2 " :showTime="true" showIcon />
          <Button label="Load" class="p-button-success mt-2 mb-2" @click="search" />
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Search..." />
          </span>
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 3rem" />
      <Column header="Time" field="ts" :sortable="true" style="max-width: 200px">
        <template #body="slotProps">
          <Badge class="product-badge mr-2" :value="dateTime(new Date(slotProps.data.ts))" />
        </template>
      </Column>
      <Column header="Infos" style="min-width: 400px">
        <template #body="slotProps">
          <Badge class="product-badge mr-2" :severity="slotProps.data.edgesCount != 0 ? 'info' : 'danger'"
            :value="slotProps.data.edgesCount + ' Edges'" />
          <Badge class="product-badge mr-2" :severity="slotProps.data.linksCount != 0 ? 'info' : 'danger'"
            :value="slotProps.data.linksCount + ' Links'" />
          <Badge class="product-badge mr-2" :severity="slotProps.data.linksActive != 0 ? 'info' : 'danger'"
            :value="slotProps.data.linksActive + ' Active links'" />
          <Badge class="product-badge mr-2" :severity="slotProps.data.capacityCount != 0 ? 'info' : 'danger'"
            :value="slotProps.data.capacityCount + ' Capacities'" />
          <Badge class="product-badge mr-2" :severity="slotProps.data.capacityPredictions != 0 ? 'info' : 'danger'"
            :value="slotProps.data.capacityPredictions + ' Capacity predictions'" />
          <Badge class="product-badge mr-2" :severity="slotProps.data.tenantHealth != 0 ? 'info' : 'danger'"
            :value="slotProps.data.tenantHealth + ' Tenant healths'" />
        </template>
      </Column>
      <template #expansion="slotProps">
        <Accordion :activeIndex="0">
          <AccordionTab header="Edges">
            <div class="orders-subtable">
              <DataTable :value="slotProps.data.details.capacities" responsiveLayout="scroll">
                <Column field="edgeName" header="Id" sortable></Column>
                <Column header="Predictive capacity RX" field="predictiveCapacityRx"></Column>
                <Column header="Predictive capacity TX" field="predictiveCapacityTx"></Column>
                <Column header="Links Total" field="linksCount"></Column>
                <Column header="Links Actives" field="linksActive"></Column>
              </DataTable>
            </div>
          </AccordionTab>
        </Accordion>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import moment from "moment";
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {

  },
  async mounted() {
    API.get("API", "/client/" + this.$route.params.id + `/chunks?start=${this.dates[0].getTime()}&end=${this.dates[1].getTime()}`).then((c) => this.chunks = c).catch((err) => console.log(err))
  },
  created() {
    this.initFilters();
  },
  data() {
    const oneDay = new Date(new Date().toUTCString())
    oneDay.setDate(oneDay.getDate() - 1);

    return {
      filters: {},
      chunks: [],
      expandedRows: null,
      dates: [oneDay, new Date(new Date().toUTCString())],
    }
  },
  methods: {
    onRowExpand(event) {
      console.log(event.data.ts)
      const index = this.chunks.findIndex((a) => a.ts == event.data.ts)
      this.chunks[index] = {
        ...this.chunks[index],
        details: {
          capacities: []
        }
      }
      API.get("API", "/client/" + this.$route.params.id + `/chunk/` + event.data.ts).then((c) => this.chunks[index].details = c)
    },
    search() {
      this.chunks = []
      API.get("API", "/client/" + this.$route.params.id + `/chunks?start=${this.dates[0].getTime()}&end=${this.dates[1].getTime()}`).then((c) => this.chunks = c)
    },
    dateTime(value) {
      return moment(value).calendar();
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteclientDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.client-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .client-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
