<template>
  <div>
    <DataTable :value="apiErrors" v-model:selection="selecteduseronlist" dataKey="id" :paginator="true" :rows="50"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[50, 100, 500]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      responsiveLayout="scroll">
      <template #header>
        <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
          <Calendar v-model="dates" selectionMode="range" class="mt-2 " :showTime="true" showIcon />
          <Button label="Load" class="p-button-success mt-2 mb-2" @click="search" />
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Search..." />
          </span>
        </div>
      </template>
      <Column header="Time" field="ts" :sortable="true" style="min-width: 200px">
        <template #body="slotProps">
          <Badge  class="product-badge mr-2" :value="dateTime(new Date(slotProps.data.ts))" />
        </template>
      </Column>
      <Column field="user" :sortable="true" header="User email"></Column>
      <Column header="Error" field="message">
        <template #body="slotProps">
          <p>{{ slotProps.data.message }}</p>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import moment from "moment";
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {

  },
  async mounted() {
    API.get("API", "/client/" + this.$route.params.id + `/api/errors/?start=${this.dates[0].getTime()}&end=${this.dates[1].getTime()}`).then((c) => this.apiErrors = c)
  },
  created() {
    this.initFilters();
  },
  data() {
    const oneWeek = new Date()
    oneWeek.setDate(oneWeek.getDate() - 7);

    return {
      filters: {},
      apiErrors: [],
      dates: [oneWeek, new Date()],
    }
  },
  methods: {
    search() {
      this.apiErrors = []
      API.get("API", "/client/" + this.$route.params.id + `/api/errors/?start=${this.dates[0].getTime()}&end=${this.dates[1].getTime()}`).then((c) => this.apiErrors = c)
    },
    dateTime(value) {
      return moment(value).calendar();
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteclientDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.client-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .client-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
