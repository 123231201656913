<template>
    <div class="row">
        <div class="col">
            <h3>Welcome, </h3>

            <div class="alert alert-info">
                {{ authData }}
            </div>

            <router-link to="/logout">
                Logout
            </router-link>
        </div>
    </div>
</template>


<script>
import { defineComponent } from 'vue'
import { Auth } from 'aws-amplify';

export default defineComponent({
    async setup() {
        const userInfo = await Auth.currentUserInfo()
        const email = userInfo.attributes['email']
        console.log(`Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`)
        return {
            authData: email,
        }
    },
})
</script>

<style>

</style>
