<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
          <!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger ml-2" @click="confirmDeleteSelected" /> -->
        </template>

        <!-- <template #end>
          <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import"
            class="mr-2 inline-block" />
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template> -->
      </Toolbar>

      <DataTable ref="dt" :value="users" v-model:selection="selecteduseronlist" dataKey="id" :paginator="true"
        :rows="10" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        responsiveLayout="scroll">
        <template #header>
          <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
            <h5 class="mb-2 md:m-0 p-as-md-center">Manage users</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>
        <!-- <Column selectionMode="single" style="width: 3rem" :exportable="false"></Column> -->

        <Column header="Avatar">
          <template #body="slotProps">
            <img :src="slotProps.data.avatar" :alt="slotProps.data.avatar" class="user-image" />
          </template>
        </Column>
        <Column field="email" header="Email" :sortable="true"></Column>
        <Column header="Accounts">
          <template #body="slotProps">
            <Badge :key="index" class="mr-2" v-for="(item, index) in slotProps.data.accounts" :value="item.name"
              severity="warning" />
          </template>
        </Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
              @click="openedituser(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
              @click="confirmDeleteuser(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="userDialog" :style="{ width: '450px' }" header="user Details" :modal="true"
      class="p-fluid">
      <div class="hello">
        <p>Complete this form to add a new user</p>
        <div class="contentForm mt-2">
          <a>Customer : </a>
          <Dropdown v-model="selectedClient" :options="clients" optionLabel="name" placeholder="Select a Customer" />
        </div>
        <div class="contentForm mt-2">
          <a>Email : </a>
          <InputText type="text" v-model="email" />
        </div>
        <div class="contentForm mt-2">
          <a>First name : </a>
          <InputText type="text" v-model="firstName" />
        </div>
        <div class="contentForm mt-2">
          <a>Last name : </a>
          <InputText type="text" v-model="lastName" />
        </div>
        <div class="contentForm mt-2">
          <a>Password : </a>
          <InputText type="text" v-model="password" />
        </div>
        <div class="contentForm mt-2">
          <a>Avatar (JPG or PNG) : </a>
          <FileUpload mode="basic" name="avatar[]" @select="myUploader" :customUpload="true" />
        </div>
        <div class="contentForm mt-2">
          <a>type : </a>
          <Dropdown v-model="selectedType" :options="types" placeholder="Select a Type" />
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="addUser" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteuserDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="user">Are you sure you want to delete <b>{{ user.email }}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteuserDialog = false" />
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteuser" />
      </template>
    </Dialog>

    <Dialog v-model:visible="edituserDialog" :style="{ width: '450px' }" header="Update user accounts" :modal="true">
      <div>
        <!-- {{ clients }} -->
        <div :key="index" v-for="(item, index) in clients">
          <div class="field-checkbox mt-2">
            <Checkbox :disabled="true" :name="item.name" :inputId="item.firestoreId" :value="item.firestoreId"
              v-model="selectedAccountsUpdate" class="mb-2" />
            <label style="min-width: 200px;" :for="item.firestoreId" class="ml-2">{{ item.name }}</label>
            <Button label="Remove" class="p-button-danger " @click="removeAccountToUser(item.firestoreId)"
              v-if="selectedAccountsUpdate.includes(item.firestoreId)" />
            <Button label="Add" class="p-button-success" @click="openEditUserAccountDialog(item.firestoreId)" v-else />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" class="p-button-text" @click="edituserDialog = false" />
      </template>
    </Dialog>


    <Dialog v-model:visible="edituserAccountDialog" :style="{ width: '450px' }" :header="'Add account for user'"
      :modal="true">
      <div>
        <p class="mb-2"> add user on account : {{ userAccountToAdd.nameVisible }}</p>
        <div class="contentForm">
          <a>type : </a>
          <Dropdown v-model="selectedType" :options="types" placeholder="Select a Type" />
        </div>
        <div class="contentForm">
          <a>First name : </a>
          <InputText type="text" v-model="firstName" default="" />
        </div>
        <div class="contentForm">
          <a>Last name : </a>
          <InputText type="text" v-model="lastName" default="" />
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="edituserAccountDialog = false" />
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="addAccountToUser" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import { API } from "aws-amplify";

export default {
  data() {
    return {
      users: null,
      userDialog: false,
      deleteuserDialog: false,
      edituserDialog: false,
      user: {},
      selecteduseronlist: null,
      filters: {},
      submitted: false,
      edituserAccountDialog: false,
      baseSelectedAccountsUpdate: [],
      selectedUserContent: null,

      userAccountIdToAdd: null,

      clients: [],
      types: ["ADMIN", "USER"],
      firstName: "",
      lastName: "",
      selectedType: "",
      selectedProfile: "",
      email: "",
      selectedClient: "",
      avatar: "",
      password: "",
    }
  },
  created() {
    this.initFilters();
  },
  async mounted() {
    API.get("API", "/firebase/users").then((c) => this.users = c)
    API.get("API", "/clients").then((c) => this.clients = c)
  },
  methods: {
    myUploader(value) {
      var reader = new FileReader();
      reader.readAsDataURL(value.files[0]);
      reader.onloadend = () => {
        var base64data = reader.result;
        this.avatar = base64data;
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return;
    },
    openNew() {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
      this.firstName = ""
      this.lastName = ""
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
      this.firstName = ""
      this.lastName = ""
    },
    addUser: async function () {
      this.submitted = true;

      if (this.firstName.length == 0 || this.lastName.length == 0 || this.selectedType.length == 0 ||
        this.email.length == 0 || this.selectedClient.length == 0 || this.avatar.length == 0) {
        alert("Missing infos")
        return
      }

      let extension = "png"

      if (this.avatar.split(',')[0].toLowerCase().includes("jpg") || this.avatar.split(',')[0].toLowerCase().includes("jpeg")) {
        extension = "jpg"
      }

      const content = {
        body: {
          email: this.email,
          clientIds: [this.selectedClient.id],
          lastName: this.lastName,
          type: this.selectedType,
          avatar: this.avatar.split(',')[1],
          avatarExtension: extension,
          password: this.password,
          firstName: this.firstName,
        }
      }
      console.log(content)
      try {
        const answer = await API.post("API", "/firebase/users", content)
        alert(answer)

      } catch (error) {
        alert(error.response.data.traceback)
        console.log(error.response.data)
      }
      this.lastName = this.firstName = this.password = this.email = ""
      this.userDialog = false;
      API.get("API", "/firebase/users").then((c) => this.users = c)
    },
    openedituser(user) {
      this.selectedAccountsUpdate = user.accounts.map((a) => a.id);
      this.baseSelectedAccountsUpdate = user.accounts.map((a) => a.id);
      this.selectedUserContent = user
      this.edituserDialog = true;
    },
    confirmDeleteuser(user) {
      this.user = user;
      this.deleteuserDialog = true;
    },
    async removeAccountToUser(accountId) {
      try {
        console.log(this.selectedUserContent, accountId)
        const answer = await API.del("API", "/firebase/users/" + this.selectedUserContent.id + "/del/" + accountId)
        alert(answer)
        this.edituserDialog = false;
        API.get("API", "/firebase/users").then((c) => this.users = c)
      } catch (error) {
        console.log(error)
      }
    },
    async addAccountToUser() {
      try {
        if (this.firstName.length == 0 || this.lastName.length == 0 || this.selectedType.length == 0) {
          alert("Missing data")
        }
        const answer = await API.post("API", "/firebase/users/" + this.selectedUserContent.id + "/add/" + this.userAccountToAdd.firestoreId, {
          body: {
            email: this.selectedUserContent.email,
            lastName: this.lastName,
            type: this.selectedType,
            avatar: this.selectedUserContent.avatar,
            firstName: this.firstName,
          }
        })
        alert(answer)
        this.edituserAccountDialog = false;
        API.get("API", "/firebase/users").then((c) => this.users = c)
      } catch (error) {
        alert(error)
        console.log(error)
      }
    },
    openEditUserAccountDialog(id) {
      this.edituserAccountDialog = true
      console.log(this.clients, id)
      this.userAccountToAdd = this.clients.find((e) => e.firestoreId == id)
      console.log(this.selectedUserContent)
      this.edituserDialog = false
      this.firstName = this.selectedUserContent.firstName
      this.lastName = this.selectedUserContent.lastName
    },
    async deleteuser() {
      this.deleteuserDialog = false;

      console.log(this.user)

      try {
        console.log(this.user.id)
        const answer = await API.del("API", "/firebase/users/" + this.user.id)
        alert(answer)
        API.get("API", "/firebase/users").then((a) => this.users = a)
      } catch (error) {
        alert(error.response.data.traceback)
        console.log(error.response.data)
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteuserDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
