<template>
  <div >
    <div>
      <Dropdown :filter="true" v-model="selectedTenant" :options="tenants" optionLabel="name"
        placeholder="Select an tenant" />
      <Calendar v-model="dates" selectionMode="range" class="mt-2 " :showTime="true" showIcon />
      <br />
      <Button label="Select" class="p-button-success mt-2 mb-2" @click="changeTenant" />

    </div>
    <div id="chart">
      <apexchart type="area" height="600" width="1400" :options="healthResult.chartOptions"
        :series="healthResult.series"></apexchart>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import { API } from "aws-amplify";
import moment from "moment";
import router from "../../app/router";

export default {
  data() {
    const oneWeek = new Date()
    oneWeek.setDate(oneWeek.getDate() - 30);

    return {
      clientDialog: false,
      deleteclientDialog: false,
      editclientDialog: false,
      client: {},
      filters: {},
      submitted: false,
      tenants: [],
      dates: [oneWeek, new Date()],
      selectedTenant: null,
      selectedTxRx: "ALL",
      healthResult: {
        series: [],
        chartOptions: {
          chart: {
            type: 'area',
            stacked: false,
            height: 350,
            // zoom: {
            //   type: 'x',
            //   enabled: true,
            //   autoScaleYaxis: true
            // },
            // toolbar: {
            //   autoSelected: 'zoom'
            // }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Health Result',
            align: 'left'
          },
          // fill: {
          //   type: 'gradient',
          //   gradient: {
          //     shadeIntensity: 1,
          //     inverseColors: false,
          //     opacityFrom: 0.5,
          //     opacityTo: 0,
          //     stops: [0, 90, 100]
          //   },
          // },
          xaxis: {
            type: 'datetime',
          },
        }
      }
    }

  },
  created() {
    this.initFilters();
  },
  async mounted() {
    API.get("API", "/client/" + this.$route.params.id).then((c) => this.tenants = c.tenants)
  },
  methods: {
    async changeTenant() {
      console.log(this.selectedTenant)

      console.log(this.dates)


      try {
        const result = await API.get("API", `/client/${this.$route.params.id}/tenant/${this.selectedTenant.id}/health?start=${this.dates[0].getTime()}`)
        console.log(result)

        this.healthResult.series = []

        this.healthResult.series.push({
          name: 'Health',
          data: result
        })
        

        // console.log(this.healthResult.series)
        // console.log(result)
      } catch (err) {
        alert(err.response.data.traceback)
      }

    },
    dateTime(value) {
      return moment(value).calendar();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return;
    },
    openNew() {
      this.client = {};
      this.submitted = false;
      this.clientDialog = true;
    },
    hideDialog() {
      this.clientDialog = false;
      this.submitted = false;
    },
    client_vue(id) {
      router.push({ path: '/clients/' + id })
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteclientDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.client-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .client-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
