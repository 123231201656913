import { Auth } from 'aws-amplify';

const awsmobile = {
    Auth: {
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "API",
                endpoint: process.env.VUE_APP_API_URL,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                  }
            }
        ]
    }
};
console.log(process.env)

export default awsmobile;
