<template>
    <div id="app">
        <authenticator hideSignUp="true">
            <div class="contents">
                <Suspense>
                    <template #default>

                        <div class="wrapper">
                            <header>
                                <img alt="Vue logo" class="logo" src="./assets/lytn-logo.png" width="40" height="40" />
                                <TabMenu :model="items" />

                            </header>
                            <div class="content">
                                <router-view />
                            </div>
                        </div>
                    </template>
                    <template #fallback>
                        <div class="wrapper">
                            <div class="content">
                                <h1>Loading...</h1>
                            </div>
                        </div>
                    </template>
                </Suspense>
            </div>
        </authenticator>
    </div>
</template>
  
<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws/export';
// import Menubar from 'primevue/menubar';
import TabMenu from 'primevue/tabmenu';

Amplify.configure(awsconfig);

</script>

<script>
export default {
    data() {
        return {
            items: [
                { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
                { label: 'Users', icon: 'pi pi-fw pi-user', to: '/users' },
                { label: 'Customers', icon: 'pi pi-fw pi-user', to: '/clients' },
                { label: 'Logout', icon: '', to: '/logout' },
            ]
        }
    }
}
</script>

<style>
body #app header {
    margin: 0;
    padding: 0;
}

.wrapper {}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    left: 50%;
}

header {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10vh;
    width: 100vw;
}
</style>
  