<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <!-- <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" /> -->
          <!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger ml-2" @click="confirmDeleteSelected" /> -->
        </template>

        <!-- <template #end>
          <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import"
            class="mr-2 inline-block" />
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template> -->
      </Toolbar>

      <DataTable ref="dt" :value="clients" v-model:selection="selectedclientonlist" dataKey="id" :paginator="true"
        :rows="10" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clients" responsiveLayout="scroll">
        <template #header>
          <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
            <h5 class="mb-2 md:m-0 p-as-md-center">Manage clients</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>
        <!-- <Column selectionMode="single" style="width: 3rem" :exportable="false"></Column> -->

        <Column header="Logo">
          <template #body="slotProps">
            <img :src="slotProps.data.logo" :alt="slotProps.data.logo" class="client-image" />
          </template>
        </Column>
        <Column field="name" header="Name"></Column>
        <Column field="active" header="Status">
          <i class="pi pi-check" style="font-size: 2rem"></i>
          <template #body="slotProps">
            <i class="pi pi-thumbs-down-fill" style="font-size: 2rem" v-if="!slotProps.data.active"></i>
            <i class="pi pi-thumbs-up-fill" style="font-size: 2rem" v-else></i>
          </template>
        </Column>
        <Column header="Tenants">
          <template #body="slotProps">
            <Badge :key="index" class="product-badge mr-2" v-for="(item, index) in slotProps.data.tenants"
              :value="item" />
          </template>
        </Column>
        <Column header="Options">
          <template #body="slotProps">
            <Badge :key="index" class="product-badge mr-2" v-for="(item, index) in slotProps.data.options" :value="item"
              severity="warning" />
          </template>
        </Column>
        <Column header="Last aggregation">
          <template #body="slotProps">
            <Badge :key="index" class="product-badge mr-2"
              :value="dateTime(new Date(slotProps.data.lastAggregation))" />
          </template>
        </Column>
        <Column header="Last capacity">
          <template #body="slotProps">
            <Badge :key="index" class="product-badge mr-2"
              :value="dateTime(new Date(slotProps.data.lastCapacity))" />
          </template>
        </Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2"
              @click="client_vue(slotProps.data.firestoreId)" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import { API } from "aws-amplify";
import moment from "moment";
import router from "../../app/router";

export default {
  data() {
    return {
      clientDialog: false,
      deleteclientDialog: false,
      editclientDialog: false,
      client: {},
      filters: {},
      submitted: false,
      clients: [],
    }
  },
  created() {
    this.initFilters();
  },
  async mounted() {
    API.get("API", "/clients").then((c) => this.clients = c)
  },
  methods: {
    dateTime(value) {
      return moment(value).calendar();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      return;
    },
    openNew() {
      this.client = {};
      this.submitted = false;
      this.clientDialog = true;
    },
    hideDialog() {
      this.clientDialog = false;
      this.submitted = false;
    },
    client_vue(id) {
      router.push({ path: '/clients/' + id })
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteclientDialog = true;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.client-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .client-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
