import { createStore } from 'vuex'

const state = {
    users: null
};

const getters = {
    users(state) {
        return state.users;
    }
};

const mutations = {
    setUsers(state, payload) {
        state.users = payload;
    }
};

export const store = createStore({
    state,
    getters,
    mutations
});
