<template>
  <div style="width: 1200px">
    <Breadcrumb :home="breadcrum.home" :model="breadcrum.items">
      <template #item="{ item }">
        <a :href="item.url">{{ item.label }}</a>
      </template>
    </Breadcrumb>
    <TabView class="mt-4">
      <TabPanel header="Home">
        
      </TabPanel> 
      <TabPanel header="API Errors">
        <ClientSingleApiLogs/>
      </TabPanel>
      <TabPanel header="Predictive capacity result">
        <ClientSingleCapacity/>
      </TabPanel>
      <TabPanel header="Chunk inspector">
        <ClientSingleChunk/>
      </TabPanel>
      <TabPanel header="Global health">
        <ClientSingleHealth/>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import ClientSingleCapacity from './ClientSingleCapacity.vue';
import ClientSingleApiLogs from './ClientSingleApiLogs.vue';
import ClientSingleChunk from './ClientSingleChunk.vue';
import ClientSingleHealth from './ClientSingleHealth.vue';

export default {
  components: {
    ClientSingleCapacity,
    ClientSingleApiLogs,
    ClientSingleChunk,
    ClientSingleHealth
  },
  data() {
    return {
      breadcrum: {
        home: { icon: 'pi pi-home', to: '/' },
        items: [
          { label: 'Customers', url: "/clients" },
          { label: this.$route.params.id },
        ]
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.client-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .client-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
